import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import EventCards from '../../components/EventCards'
import NoResultsText from '../../components/NoResultsText'
import { PageContext } from '../../context/ApplicationState'

const MonthGroup = styled.div`
  .section-title {
    + ul {
      margin-top: 20px;

      @media screen and (min-width: ${props => props.theme.responsive.large}) {
        margin-top: 40px;
      }
    }
  }
`

const Calendar = () => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulCalendarEvent(
        filter: { calendar: { eq: "Maskcara Artists" } }
        sort: { fields: [startTime], order: [ASC] }
      ) {
        edges {
          node {
            title
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            startTime
            endTime
          }
        }
      }
    }
  `)

  const contentfulEvents = data.allContentfulCalendarEvent.edges

  const futureEvents = contentfulEvents.filter(
    ({ node: event }) => dayjs(event.startTime) > dayjs().subtract(30, 'minutes')
  )

  const groupEventsByMonth = events => {
    const groupedEvents = {}

    for (const event of events) {
      const date = dayjs(event.node.startTime)
      const key = date.format('YYYYMM')
      if (!groupedEvents[key]) {
        groupedEvents[key] = []
      }
      groupedEvents[key].push(event)
    }

    const eventsByMonth = []
    for (const events of Object.values(groupedEvents)) {
      const eventObj = {
        dateHeading: dayjs(events[0].node.startTime).format('MMMM YYYY'),
        events,
      }
      eventsByMonth.push(eventObj)
    }
    return eventsByMonth
  }

  const eventsByMonth = groupEventsByMonth(futureEvents)
  const totalEventsByMonth = eventsByMonth.length

  return (
    <>
      {totalEventsByMonth > 0 ? (
        eventsByMonth.map((monthOfEvents, index) => {
          return (
            <MonthGroup
              className="section"
              key={`${monthOfEvents.events[0].id}${index}`}
            >
              <h3 className="section-title">{monthOfEvents.dateHeading}</h3>
              <EventCards events={monthOfEvents.events} />
            </MonthGroup>
          )
        })
      ) : (
        <NoResultsText>
          There aren't currently any upcoming events
        </NoResultsText>
      )}
    </>
  )
}

export default Calendar
